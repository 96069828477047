import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout/layout';
import PageHero from '../components/hero/PageHero';
import PostsList from './list-posts-template';

import heroImage from '../images/laptops-at-workstations-coding.jpg';

const ServicesList = ({ data, pageContext }) => (
  <Layout>
    <SEO
      title="Services"
      description="New Normal web and mobile development case studies. We are experts in Seattle."
    />
    <PageHero image={heroImage}>Services</PageHero>
    <PostsList data={data} pageContext={pageContext} prefix="services" />
  </Layout>
);

export default ServicesList;

export const query = graphql`
  query servicesMarkdown($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//services//"}
        frontmatter: {
          visibility: {eq: "yes"}
        }
      },
      sort: {fields: [frontmatter___date], order: DESC}
      limit: $limit
      skip: $skip
    ) {
      edges {
        ...MarkdownPostNode
      }
    }
  }
`;
